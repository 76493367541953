import {React, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from 'axios';

import avatar from '../assets/images/avatar.png';

import './styles/editprofile.scss';

const MAX_PROFILE_PIC_SIZE = 1024*500; // 500 KB

function Editprofile() {

    const history = useHistory();

    const [msg, setMsg] = useState('');
    const [picMsg, setPicMsg] = useState('');
    
    const [buttonText, setButtonText] = useState('Save');

    const [data, setData] = useState({
        name: "", email: "", mobile_no: "", address: "", avatar: null
    });

    const handleInputs = e => setData({...data, [e.target.name]: e.target.value});

    const updateProfile = async e => {
        e.preventDefault();
        
        const { mobile_no, address } = data;
        
        if(mobile_no < 100000000 || mobile_no > 9999999999){
            setMsg('Mobile number must be of 10 digits');
            return; 
        }
        if(!address || address.length > 150 || address.length < 10){
            setMsg("Address must contain 10 - 150 charachters");
            return;
        }

        setButtonText('Saving');

        try {
            const response = await axios({
                method: "PATCH",
                url: "/api/profile",
                data: {
                    mobile_no, address
                }
            });

            if(response.status === 200){
                setMsg("Profile updated successfully");
                setTimeout(() => {
                    history.push('/pass');
                }, 500);
            } else 
                setMsg('Something went wrong please try again later');

        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setMsg(emsg);
            else
                setMsg("Cannot update profile. Please try again later");  
        } finally{
            setButtonText('Save');
        }
    }

    const updateProfilePic = async e => {
        setPicMsg('Uploading...');
        document.querySelector('.error_msg').classList.remove('success_msg');

        const photo = e.target.files[0];
        // Checking for photo object otherwise properties will be undefined
        if(photo && photo.size > MAX_PROFILE_PIC_SIZE){
            setPicMsg('Picture size should be less than 500 KB');
            return;
        }
        if(photo && (photo.type !== 'image/png' && photo.type !== 'image/jpg' && photo.type !== 'image/jpeg')){
            setPicMsg('Picture should be of JPG/PNG/JPEG format');
            return;
        }

        const formData = new FormData();
        formData.append('profile_pic', photo);

        try {
            const response = await axios.post('/api/profile_pic', formData);
            if(response.status === 200){

                // Adding green color for success message
                document.querySelector('.error_msg').classList.add('success_msg');
                setPicMsg('Profile picture updated sucessfully');
                
                // Setting and showing the newly set picture
                setData({
                    ...data,
                    avatar: response.data.file_path
                })
            }
        } catch (error) {
            setPicMsg('Some error occured. Please try again later');
        }
    }

    const loadUserData = async () => {
        try {
            const response = await axios.get('/api/profile');
            if(response.status === 200)
                setData(response.data);
        } catch (error) {
            setMsg("Cannot load user data");
            history.push('/pass');
        }
    }

    useEffect(() => {
        loadUserData();

        // Disabling eslint empty dependency warning
        // eslint-disable-next-line
    }, []);

    return (
        <div className="edit_profile_wrapper">
            <div className="edit_container">
            <div className="edit_header">
                <IconButton onClick={() => history.goBack()}>
                    <ArrowBackIcon />
                </IconButton>
                <h2>Edit Profile</h2>
            </div>
                <form>
                    <div className="profile_pic">
                        <img src={
                            data.avatar === null ? avatar : data.avatar
                        } alt="User Profile pic" />
                        <input 
                            type="file" 
                            id="new_pic" 
                            accept="image/*" 
                            onChange={updateProfilePic}
                            />
                        <label htmlFor="new_pic">
                            <Button 
                                color="primary"
                                variant="contained"
                                startIcon={<AddAPhotoIcon />}
                                component="span"
                            >                            
                            Upload New
                            </Button>
                        </label>
                    </div>
                    <div className='error_msg'>{picMsg}</div>
                    <label>
                        Name
                        <input 
                        type="text" 
                        value={data.name}
                        name="name"
                        disabled
                        onChange={handleInputs}  />
                    </label>
                    <label>
                        Email
                        <input
                            value={data.email}
                            disabled
                        />
                    </label>
                    <label>
                        Mobile Number
                        <input 
                        type="number"
                        value={data.mobile_no}
                        name="mobile_no"
                        onChange={handleInputs} />
                    </label>
                    <label>
                        Address
                        <textarea 
                            value={data.address}
                            name="address" 
                            onChange={handleInputs}>
                        </textarea>
                    </label>
                    <div className="error_msg">{msg}</div>
                    <button className="btn" onClick={updateProfile}>{buttonText}</button>
                </form>
                <Link to="/pass">Back to home</Link>
            </div>            
        </div>
    )
}

export default Editprofile
