import React from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Error } from '@material-ui/icons';
import QRCode from 'qrcode.react';

import avatar from '../assets/images/avatar.png';

const Pass = ({userData}) => {
    return (
        <div className="card">
            <div className="qr_code">
                <QRCode value={userData.email} />
            </div>
            <div className="details">
                <div className="primary_details">
                    <img src={
                        userData.avatar === null ? avatar : userData.avatar
                    } alt="profile" />
                    <div className="user_name">
                        <span>Name:</span>
                        {userData.name}
                        {
                            userData.has_valid_pass ?
                            <VerifiedUserIcon style={{fill: '#2ed573'}} />
                            :
                            <Error style={{fill: '#ff4757'}} />
                        }
                    </div>
                </div>
                <ul className="other_details">
                    <li>
                        <span>Bus Number</span>
                        <p>{userData.is_student.bus_number}</p>
                    </li>
                    <li>
                        <span>Seat Number</span>
                        <p>{userData.is_student.seat_number}</p>
                    </li>
                    <li>
                        <span>Issue Date</span>
                        <p>{new Date(userData.is_student.issued_on).toLocaleDateString()}</p>
                    </li>
                    <li>
                        <span>Valid Till</span>
                        <p>{new Date(userData.is_student.valid_till).toLocaleDateString()}</p>
                    </li>
                    <li>
                        <span>Address</span>
                        <p>{userData.address}</p>
                    </li>
                    <li>
                        <span>Mobile Number</span>
                        <p>{userData.mobile_no}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Pass
