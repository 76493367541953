import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import axios from 'axios';

const RevokeAuthModal = (props) => {

    const {open, handleClose, setPopupState, setPopupMessage, setPopupSeverity} = props;

    const revokeAuth = async () => {
        try {
            const response = await axios.patch('/api/revoke_auth');
            if(response.data.success){
                setPopupMessage(response.data.success);
                setPopupSeverity('success');
            }
        } catch (error) {
            setPopupMessage("Something went wrong. Please try again later");
            setPopupSeverity('error');
        }finally{
            setPopupState(true);
            handleClose();
        }
    }

    return(
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Revoke authorisations</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to revoke all authorisations?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={revokeAuth} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      );
}

export default RevokeAuthModal;