import React from 'react';
import error from './assets/images/error404.jpg';
import './error.scss';
import {NavLink} from 'react-router-dom';

function Error404() {
    return (
        <div className="error_page">
            <div className="image_preview">
                <img src={error} alt="Page not found" />
            </div>
            <p>The page you are looking for doesn't exists on our server.</p>
            <div className="home_link">
                <NavLink to="/">Back to home</NavLink>
            </div>
        </div>
    )
}

export default Error404
