import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';

import AuthProvider from './auth/AuthProvider';

import App from './App';
import './index.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#403cd0',
    },
    secondary: {
      main: '#4043cd0',
    },
  },
});


// removing loading aniation from html page
const loadingDiv = document.querySelector('.loading_animation');
loadingDiv && loadingDiv.remove();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
