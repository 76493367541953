import axios from 'axios';
import React, { createContext, useEffect, useReducer, useMemo } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

export const AuthContext = createContext();

const initialAuthState = {
    isLoggedIn: false,
    userGroup: null,
    isLoading: true
}

const authReducer = (state, action) => {
    switch(action.type){
        case 'LOADED': 
            return {
                ...state,
                isLoading: false
            }
        case 'LOGIN': 
            return {
                ...state,
                isLoggedIn: true,
                userGroup: action.userGroup
            }

            // need to work upon this
        // case 'REGISTER': 
        //     return {
        //         ...state,
        //         isLoading: true,
        //         user_group: action.userGroup
        //     }
            
        // can send request to server to delete jwts
        case 'LOGOUT': 
            return {
                ...state,
                isLoggedIn: false,
                userGroup: null
            }

        default: 
            return state;
    }
}


const AuthProvider = ({children}) => {

    const authMemo = useMemo(() => ({

        login: userGroup => {
            dispatch({type: 'LOGIN', userGroup: userGroup});
        },

        // function to remove cookies and logout user, since cookies were set with httpOnly flag
        // for increasing security, it needs to be removed from serverside
        logout: async () => {
            await axios.get('/api/logout');
            dispatch({type: 'LOGOUT'});
        }
    }), []);
    
    const [authState, dispatch] = useReducer(authReducer, initialAuthState);

    /*Checking for authentication on initial app load
    this function only run once and save the user data in authState
    */
    useEffect(() => {

        const checkAuthentication = async () => {
            try{
                const response = await axios.get('/api/check_login');
                if(response.status === 200)
                    dispatch({type: 'LOGIN', userGroup: response.data.user_group});
            } catch(e){
                console.log(e);
            } finally{
                dispatch({type: 'LOADED'});
            }
        }

        checkAuthentication();
    }, []);

    // If the authentication hasnt been checked show loading component
    if (authState.isLoading)
        return <CircularProgress />


    return (
        <AuthContext.Provider
            value={{
                authState,
                authMemo
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
