import {React, useState, useEffect} from 'react';
import { FormHelperText, Select, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';

const EditBusModal = (props) => {

    const { editModalOpen, handleEditModalClose, driversAvailable, tempBus,
            setPopupMessage, setPopupSeverity, setPopupState, setBusDataChanged } = props;

    // used this varaible to handle form inputs & store the current bus selected
    // it had to be used as there is no other way to update temp bus
    const [bus, setBus] = useState({});
    
    const [msg, setMsg] = useState('');
    const [driver, setDriver] = useState('');
    const [driverId, setDriverId] = useState('');
    
    // Bus details are received from the parent component as 'tempbus' & are stored in 'bus'
    // Whenever edit button is clicked 'tempbus' is changed and 'bus' is updated
    useEffect(() => { setBus(tempBus) }, [tempBus]);
    
    // handle form inputs
    const handleInputs = e => setBus({...bus, [e.target.name]: e.target.value});

    /* The driverId is set on click of the driver name
        using the data-id attribute set on rendering the drivers
    */
    const handleChange = (event) => {
        setDriver(event.target.value);
    };

    const updateBus = async () => {
        const { seating_capacity, route } = bus;
        setMsg('');
        if(seating_capacity < 10){
            setMsg("Seating capacity must be greater than 10");
            return;
        }
        else if(!route || (route.length < 10 && route.length > 250)){
            /* 
            type checking for 'route' as length is undefined for other variables,
            hence run time error
            */
            setMsg("Route length must be within 10 - 250 characters");
            return;
        }

        try {
            const response = await axios({
                method: "PATCH",
                url: "/api/bus",
                data: {
                    bus_no: bus.bus_no, seating_capacity, route, driver_assigned: driverId
                }
            });

            if(response.status === 200){
                if(response.data.success){
                    setPopupMessage(response.data.success);
                    setPopupSeverity('success');

                    // Changing busdatachange so that bus data loads again
                    setBusDataChanged(preVal => !preVal);
                } else if(response.data.error){
                    setPopupMessage(response.data.error);
                    setPopupSeverity('error');
                }
            }
        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setMsg(emsg)
            else 
                setMsg('An unknown error occured. Please try again later');
        }finally{
                setPopupState(true);
                handleEditModalClose();
        }
    }
    
    return(
        <Dialog open={editModalOpen} onClose={handleEditModalClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Bus Data</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Please enter the details
                <form>
                    <TextField 
                        disabled
                        label="Bus number"
                        value={bus.bus_no}
                        type="number"
                        fullWidth />
                    <TextField 
                        label="Seating capacity"
                        name="seating_capacity"
                        value={bus.seating_capacity}
                        onChange={handleInputs}
                        fullWidth />
                    <TextField 
                        label="Route"
                        name="route"
                        value={bus.route}
                        onChange={handleInputs}
                        multiline
                        fullWidth />
                    <InputLabel id="selectDriver">Driver</InputLabel>
                    <Select
                        labelId="selectDriver"
                        value={driver}
                        onChange={handleChange}
                        >
                        {/* Setting driver id as null so that on selecting none
                        previous driver id should be reset*/}
                        <MenuItem value="" onClick={()=>setDriverId(null)}>
                            <em>None</em>
                        </MenuItem>
                        {/* Using array.map since if driversAvailable is empty then .map is not a function */}
                        {Array.from(driversAvailable).map((option) => (
                            <MenuItem key={option._id} value={option.name}
                                onClick={() => setDriverId(option._id)}>
                            {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        Please select from available drivers
                    </FormHelperText>
                </form>
                <div className="error_msg">{msg}</div>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleEditModalClose} color="primary">
                Cancel
            </Button>
            <Button onClick={updateBus} color="primary">
                Update
            </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditBusModal
