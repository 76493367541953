import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@material-ui/core';
import axios from 'axios'; 

const AddDriverModal = props => {

    const { open, handleClose, setPopupState, setPopupMessage, setPopupSeverity, setDriverDataChanged} = props;
    const [contactNo, setContactNo] = useState();
    const [msg, setmsg] = useState('')

    const addDriver = async () => {

        setmsg('');

        if(!contactNo || contactNo.length !== 10){
            setmsg('Contact number must be of 10 digits');
            return;
        }

        try {

            const response = await axios({
                method: 'PATCH',
                url: '/api/driver',
                data: {
                    mobile_no: contactNo
                }
            });

            if(response.status===200){
                if(response.data.success){
                    setPopupMessage('Driver added successfully !!')
                    setPopupSeverity('success');

                    // Changing driverdatachange so that driver data loads again
                    setDriverDataChanged(preVal => !preVal);
                } else {
                    setPopupMessage('Some error occured. Please try again later');
                    setPopupSeverity('error');                    
                }
            }
            
        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setPopupMessage(emsg);
            else 
                setPopupMessage('Some error occured. Please try again later');

            setPopupSeverity('error');
        } finally{
            setPopupState(true);
            handleClose();
        }
    }

    return(
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle>Add new driver</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter contact number
                    <form>
                        <TextField
                            type="number"
                            value={contactNo}
                            onChange={e=>setContactNo(e.target.value)}
                            label="Contact number"
                            fullWidth />
                    </form>
                </DialogContentText>
                <div className="error_msg">{msg}</div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={addDriver} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddDriverModal;
