import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import { NavLink } from 'react-router-dom';

function Passpagefooter() {
    return (
        <footer>
            <ul>
                <li>
                    <NavLink to="/pass" activeClassName="active">
                        <HomeIcon />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/settings" activeClassName="active">
                        <SettingsIcon />
                    </NavLink>
                </li>
            </ul>
        </footer>
    );
}

export default Passpagefooter;
