import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";

import './styles/login.scss';

const ForgotPassword = () => {

    document.title = "Forgot Password";

    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [msgColor, setMsgColor] = useState();

    const requestLink = async e => {
        e.preventDefault();

        // check for email type
        // eslint-disable-next-line
        if(!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
            setMsg("Invalid email type"); 
            return;
        }

        try {
            const response = await axios({
                method: "POST",
                url: "/api/forgot_password",
                data: {
                    email
                }
            });

            if(response.status === 200){
                setMsg(response.data.success);
                setMsgColor({color: "#2ed573"});
            }
            
        } catch (error) {  
            console.log(error);
            let errorMessage = error.response.data.error;
            if(errorMessage){
                setMsg("*"+ errorMessage);
                setMsgColor({});
            }
            else 
                setMsg("Something went wrong try again later");
        }
    }

  return (
    <div className="container fp_c">
        <div className="form_container">
            <h2>Forgot Password ?</h2>
            <p>Enter your email to get a link to reset your password.</p>
            <form>
                <label>
                    Email
                    <input 
                        type="email" 
                        placeholder="abc@xyz.com" 
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                        />
                </label>
                <Link to="/login">Back to login</Link>
                <div className="error_msg" style={msgColor}>{msg}</div>
                <button className="btn" onClick={requestLink}>Request Link</button>
            </form>
        </div>
    </div>
  )
}

export default ForgotPassword