import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 10
  }
});

export default function NoPass() {
  const classes = useStyles();

  return (
    <div className="userdata_container">
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    OOPs! No pass found
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    No student pass found for this account. Please contact the administrator for more details.
                </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </div>
  );
}
