import {React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Button, Tooltip } from '@material-ui/core';
import axios from 'axios';

import Navbar from '../components/Navbar';
import DriversTable from '../components/tables/DriversTable';
import Popup from '../components/dialogs/Popup';
import AddDriverModal from '../components/dialogs/AddDriverModal';
import DeleteDriverModal from '../components/dialogs/DeleteDriverModal';

import './styles/container.scss';

import no_data from '../assets/images/no_data.jpg';

function Drivers() {

    document.title = "Drivers";
    const history = useHistory();

    // Whenever this data changes loadDrivers is called again as it is a dependency in useffect
    // changed in add / delete  driver
    const [driverDataChanged, setDriverDataChanged] = useState(false);

    const [popupState, setPopupState] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupSeverity, setPopupSeverity] = useState('');

    // Storing temp driver for delete button
    const [tempDriver, settempDriver] = useState({});

    const [driversData, setDriversData] = useState({});
    const loadDrivers = async () => {
        try {
            
            const response = await axios.get('/api/drivers');
            if(response.status === 200){
                setDriversData(response.data)
            }
            
        } catch (error) {
            if(error.response){
                if(error.response.status === 401)
                    history.push('/login');
            }
        }
    }

    useEffect(() => {
        loadDrivers();

        // Disabling eslint empty dependency warning
        // eslint-disable-next-line
    }, [driverDataChanged]);

    const [open, setOpen] = useState(false);
    const [dmopen, setdmOpen] = useState(false); //For delete modal

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }; 
    
    //For delete modal
    const dmhandleClickOpen = (driver) => {
        settempDriver(driver);
        setdmOpen(true);
    };

    const dmhandleClose = () => {
        setdmOpen(false);
    }; 
    return (
        <>
        <div className="container">
        <Navbar />
            <div className="data_wrapper">
                <h1>Drivers</h1>
                <div className="bus_data">
                    {
                        driversData.error ?
                        (
                            <div className='no_data_div'>
                                <h3>OOPs!! no data found</h3>
                                <img 
                                    src={no_data} 
                                    alt='No data found' 
                                    class="no_data_image"
                                />
                            </div>
                        )
                        :
                        <DriversTable drivers={driversData} handleClickOpen={handleClickOpen} dmhandleClickOpen={dmhandleClickOpen} />
                    }
                </div>
            </div>
            <Tooltip title="Add a driver" arrow>   
                <Button 
                    startIcon={<PersonAddIcon />}
                    variant="contained"
                    className="add_btn"
                    color="primary"
                    onClick={handleClickOpen}
                >
                    Add new Driver
                </Button>
            </Tooltip>
        </div>
        <AddDriverModal 
            open={open} 
            handleClose={handleClose} 
            setPopupState={setPopupState}
            setPopupMessage={setPopupMessage}
            setPopupSeverity={setPopupSeverity}
            setDriverDataChanged={setDriverDataChanged}
            />
        <DeleteDriverModal 
            dmopen={dmopen} 
            dmhandleClose={dmhandleClose} 
            driver={tempDriver}
            setPopupState={setPopupState}
            setPopupMessage={setPopupMessage}
            setPopupSeverity={setPopupSeverity}
            setDriverDataChanged={setDriverDataChanged}
        />
        <Popup
            open={popupState}
            severity={popupSeverity}
            message={popupMessage}
            setPopupState={setPopupState}
        />
        </>
    )
}

export default Drivers;
