import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import avatar from '../../assets/images/avatar.png';

const columns = [
  { 
    id: 'avatar', 
    label: 'Profile Picture', 
    minWidth: 170 
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 170,
  },
  {
    id: 'bus_no',
    label: 'Bus Number',
    minWidth: 170,
  },
  {
    id: 'mobile_no',
    label: 'Contact Number',
    minWidth: 170,
  },
  {
    id: 'address',
    label: 'Address',
    minWidth: 170,
  },
  {
      id: 'delete',
      label: 'Delete'
  }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

function BusTable({drivers, dmhandleClickOpen}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          
          {/* Array.from() is used if one data will be there it will result in error 
          as it will be an object and not array of objects */}

            {Array.from(drivers).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((driver) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={driver.bus_no}>
                  {columns.map((column) => {
                    const value = driver[column.id];
                    
                    //Checking for columns whether it is button, picture or data
                    if(column.id === 'avatar'){
                      return (
                        <TableCell key={column.id}>
                          <img 
                            src={avatar} 
                            alt="Driver's profile pic" 
                            className="driver_profile_picture"  
                          />
                        </TableCell>
                      )
                    }
                    if(column.id === 'delete'){
                      return (
                        <TableCell key={column.id}>
                          <IconButton onClick={() => dmhandleClickOpen(driver)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      );
                    } else
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={drivers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default BusTable;
