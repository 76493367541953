import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from "axios";

import './styles/login.scss';
import Loader from '../components/Loader';

const ResetPassword = () => {

    document.title = "Reset Password";

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [cpassword, setcPassword] = useState('');
    const [msg, setMsg] = useState();
    const [msgColor, setMsgColor] = useState({})
    const [validLink, setValidLink] = useState(false);

    // this is always valid as component is only rendered if has at least 2 query params
    const validator = useParams()[0];
    const { selector } = useParams();

    useEffect(() => {
        const checkLink = async () => {
            try {
                const response = await axios({
                    method: 'POST',
                    url: '/api/check_password_reset_tokens',
                    data: {
                        selector, validator
                    }
                });

                if(response.status === 200){
                    setValidLink(true);
                }
            } catch (error) {
                console.log(error);
            } finally{
                setIsLoading(false);
            }
        }

        checkLink();
        // eslint-disable-next-line
    }, []);

    const __ = async e => {
        e.preventDefault();
        setMsg("");

        if(password.length > 30 || password.length < 8){
            setMsg("* Password must contain 8 - 30 charachters");
            return;
        }

        if(password !== cpassword){
            setMsg("Password do not match");
            return;
        }

        try {
            const response = await axios({
                method: "POST",
                url: "/api/reset_password",
                data: {
                    password, selector, validator
                }
            });

            if(response.status === 200){
                setMsg(response.data.success);
                setMsgColor({color: "#2ed573"});
                setTimeout(()=>{
                    history.push('/login');
                }, 500);
            }
            
        } catch (error) {  
            console.log(error);
            let errorMessage = error.response.data.error;
            if(errorMessage)
                setMsg("*"+ errorMessage);
            else 
                setMsg("Something went wrong try again later");
        }
    }

  return (
    <div className="container fp_c">
        <div className="form_container">
            {
                isLoading ?
                <Loader />
                :
                validLink ?
                <>
                    <h2>Reset Password</h2>
                    <p>Please enter your new password. Make sure it is strong and has at least of 8 charachters
                    </p>
                    <form>
                        <label>
                            Password
                            <input 
                                type="password" 
                                placeholder="New Password" 
                                value={password}
                                onChange={e=>setPassword(e.target.value)}
                                />
                        </label>
                        <label>
                            Re-enter new password
                            <input 
                                type="password" 
                                placeholder="Confirm New Password" 
                                value={cpassword}
                                onChange={e=>setcPassword(e.target.value)}
                                />
                        </label>
                        <Link to="/login">Back to login</Link>
                        <div className="error_msg" style={msgColor}>{msg}</div>
                        <button className="btn" onClick={__}>Reset Password</button>
                    </form>
                </>

                :

                <>
                  <h2>Invalid Link</h2>  
                  <p>Link expired or is invalid. Please request for password reset link again by clicking <Link to="/forgot_password">here</Link>.</p>
                </>
            }
        </div>
    </div>
  )
}

export default ResetPassword