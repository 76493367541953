import { React, useState } from 'react';
import axios from 'axios';
import { Button, TextField } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import Navbar from '../components/Navbar';
import avatar from '../assets/images/avatar.png';
import Popup from '../components/dialogs/Popup';

import './styles/container.scss';

const Details = ({data}) => {

    const [popupState, setPopupState] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupSeverity, setPopupSeverity] = useState('');
    const [newBusNo, setnewBusNo] = useState();

    const validity = data.has_valid_pass === true ? true : false;

    const extendValidity = async () => {

        if(!newBusNo){
            setPopupMessage("Please enter the bus number");
            setPopupSeverity('error');
            setPopupState(true);
            return;
        }

        try {
            const response = await axios({
                method: 'PATCH',
                url: '/api/student',
                data: {bus_no: newBusNo, email: data.email}
            });

            if(response.status === 200){
                let msg = response.data;
                if(msg.success){
                    setPopupMessage(msg.success);
                    setPopupSeverity('success');
                }
                else if(msg.error){
                    setPopupMessage(msg.error);
                    setPopupSeverity('error');
                }
            }
            
        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setPopupMessage(emsg);
            else
                setPopupMessage("Something went wrong. Please try again later");
            setPopupSeverity('error');;
        }finally{
            setPopupState(true);
        }
    }

    return(
        <>
        <div className="profile_details">
            <div className="profile_pic">
                <img src={data.avatar ? data.avatar : avatar} alt="Student profile pic" />
            </div>
            <ul>
                <li>
                    <p>Name</p>
                    <p>
                        {data.name} 
                        <VerifiedUserIcon style={validity ? {fill: '#2ed573'} : {fill: '#ff4757'}} />
                    </p>
                </li>
                {/* 
                Only show these fields if the user group is student
                 */}
                {data.is_student &&
                <>
                <li>
                    <p>Current Bus Number</p>
                    <p>{data.is_student.bus_number}</p>
                </li>
                <li>
                    <p>Seat Number</p>
                    <p>{data.is_student.seat_number}</p>
                </li>
                <li>
                    <p>Issued on</p>
                    <p>{new Date(data.is_student.issued_on).toDateString()}</p>
                </li>
                <li>
                    <p>Valid till</p>
                    <p>{new Date(data.is_student.valid_till).toDateString()}</p>
                </li>
                </>}
                <li>
                    <p>Address</p>
                    <p>{data.address}</p>
                </li>
            </ul>
        </div>
        <form>
            <TextField
                type="number"
                label="Enter new bus number"
                name="new_bus_no"
                value={newBusNo}
                onChange={e => setnewBusNo(e.target.value)}
            />
            <Button 
                variant="contained"
                color="primary"
                onClick={extendValidity}
            >
            Extend Validity
            </Button>
        </form>
        <Popup
            open={popupState}
            severity={popupSeverity}
            message={popupMessage}
            setPopupState={setPopupState}
        />
        </>
    );
}

function Updatestudent() {

    document.title = "Update Student";

    const [dataLoaded, setdataLoaded] = useState(false);
    const [email, setemail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [studentData, setstudentData] = useState({});

    const studentDataLoader = async () => {
        setErrorMsg('');

        if(email.length<10 || email.length>100){
            setErrorMsg('email must be within 10 - 100 characters');
            return;
        }

        try {
            const response = await axios.get(`/api/student/${email}`);
            if(response.status === 200){
                let data = response.data;
                // Not using previous state since it stores the data of previous student which is misleading
                setstudentData(data);
                setdataLoaded(true);
            }
        } catch (error) {
            if(error.response){
                let msg = error.response.data.error;
                if(msg) 
                    setErrorMsg(msg);
            } else{
                setErrorMsg('Something went wrong try again later');
            }
        }
    }

    return (
        <div className="container">
            <Navbar />
            <div className="data_wrapper add_student">
                <div className="left_col">
                <h2>Add/Update Student</h2>
                    <form onSubmit={e => e.preventDefault()}>
                        <TextField 
                            name="email"
                            type="email"
                            value={email}
                            label="Enter student's email"
                            onChange={e => setemail(e.target.value)}
                            fullWidth
                        />
                        <Button variant="contained" color="primary" onClick={studentDataLoader}>Fetch details</Button>
                    </form>      
                    <div className="error_msg">{errorMsg}</div>              
                </div>
                <div className="right_col">
                    <h2>Details</h2>
                    {dataLoaded ? <Details data={studentData} /> : ''}                    
                </div>
            </div>
            
        </div>
    );
}

export default Updatestudent;
