import {React, useState, useEffect} from 'react';
import { Button, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import axios from 'axios';

import Popup from '../components/dialogs/Popup';
import Navbar from '../components/Navbar';
import RevokeAuthModal from '../components/dialogs/RevokeAuthModal';
import UpdateTermModal from '../components/dialogs/UpdateTermPopup';

// Date picker
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function Dashboard() {

    document.title = "Dashboard";

    const [stats, setStats] = useState({current_term: {}})

    // Popup (Snackbar) states
    const [popupState, setPopupState] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupSeverity, setPopupSeverity] = useState('');

    // Modal states
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Update term Modal states
    const [updateTermOpen, setUpdateTermOpen] = useState(false);
    const updateTermHandleOpen = () => setUpdateTermOpen(true);
    const updateTermHandleClose = () => setUpdateTermOpen(false);

    // Date state
    const [termEndDate, setTermEndDate] = useState(new Date());
    const handleDateChange = date => setTermEndDate(date);

    const [state, setState] = useState({
        logins: true,
        signup: true,
    });

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.checked });
        handleSettingChange(e.target.name, e.target.checked);
    }

    const handleSettingChange = async (key, value) => {
    
        if(key !== 'logins' && key !== 'signup')
            return;

        try {

            const response = await axios({
                method: 'PATCH',
                url: '/api/appsettings',
                data: { key, value }
            });
            
            if(response.status === 200){
                setPopupMessage('Successfully updated!!');
                setPopupSeverity('success');
            }

        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setPopupMessage(emsg);
            setPopupSeverity('error');
        }finally{
            setPopupState(true);
        }
    }

    const getStats = async () => {
        try {
            
            const response = await axios.get('/api/stats');
            if(response.status === 200)
                setStats(response.data);

        } catch (error) {

        }
    }

    useEffect(() => {
        getStats();
    }, [])

    const currentDate = new Date().toDateString();

    return (
        <>
        <div className="container">
            <Navbar />
            <div className="dashboard_container">
                <h1>
                    Dashboard
                </h1>
                <div className="dashboard_wrapper">
                    <div className="stats">
                        <h3>Stats</h3>
                        <ul className="stats_details">
                            <li>
                                <p>Total number of students</p>
                                <p>{stats.students_count}</p>
                            </li>
                            <li>
                                <p>Total number of buses</p>
                                <p>{stats.buses_count}</p>
                            </li>
                            <li>
                                <p>Total number of temporary users</p>
                                <p>{stats.temp_users_count}</p>
                            </li>
                            <li>
                                <p>Current term</p>
                                <p>
                                    {new Date(stats.current_term.start).toDateString()} -
                                    {new Date(stats.current_term.end).toDateString()}    
                                </p>
                            </li>
                            <li>
                                <p>Set new term</p>
                                <p>
                                    <span>{currentDate}</span> to
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Select Term End Date"
                                            value={termEndDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </p>
                            </li>
                        </ul>
                        <div className="update_btn">
                            <Button variant="contained" color="primary" onClick={updateTermHandleOpen}>Update Term</Button>
                        </div>
                    </div>
                    <div className="app_settings">
                        <h3>App Settings</h3>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch 
                                    checked={state.logins} 
                                    color='primary'
                                    name='logins'
                                    onChange={handleChange}
                                />}
                                label='Logins enabled'
                            />
                            <FormControlLabel
                                control={<Switch 
                                    checked={state.signup} 
                                    color='primary'
                                    name='signup'
                                    onChange={handleChange}
                                />}
                                label='Signup enabled'
                            />
                        </FormGroup>
                        <div className="other_settings">
                            <h3>Revoke all Authorisations</h3>
                            <Button variant="contained" color="primary" onClick={handleOpen}>
                                Revoke 
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RevokeAuthModal 
            open={open}
            handleClose={handleClose}
            setPopupState={setPopupState}
            setPopupMessage={setPopupMessage}
            setPopupSeverity={setPopupSeverity}
        />
        <UpdateTermModal 
            open={updateTermOpen}
            handleClose={updateTermHandleClose}
            setPopupState={setPopupState}
            setPopupMessage={setPopupMessage}
            setPopupSeverity={setPopupSeverity}
            termEndDate={termEndDate}
        />
        <Popup
            open={popupState}
            severity={popupSeverity}
            message={popupMessage}
            setPopupState={setPopupState}
        />
        </>
    )
}

export default Dashboard
