import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns = [
    { 
      id: 's_no', 
      label: 'S. No.', 
      minWidth: 20,
    },
    {
      id: 'name',
      label: 'Student Name',
      minWidth: 150,
    },
    {
      id: 'email',
      label: 'Student email',
      minWidth: 170,
    }
];

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
  });

const AttendanceTable = (props) => {

  const { attendanceList } = props;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>

            {/* Array.from() is used if one data will be there it will result in error 
            as it will be an object and not array of objects */}
              {Array.from(attendanceList).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={student.s_no}>
                    {columns.map((column) => {
                      if(column.id === 's_no')
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {index + 1}
                          </TableCell>
                        )
                      else
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {student[column.id]}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={attendanceList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    )
}

export default AttendanceTable
