import {React, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";

import { AuthContext } from "../auth/AuthProvider";

import bus from '../assets/images/bus.png';
import login from '../assets/images/login.jpg';
import android from '../assets/images/android.png';

import './styles/login.scss';

function Login() {
    document.title = "Login";

    // using history hook for pushing default route after login and not render the error component
    const history = useHistory();
    
    const {authMemo} = useContext(AuthContext);

    const [visible, setVisible] = useState(false);
    const [pwdtype, setpwdtype] = useState('password');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    // error messages
    const [msg, setMsg] = useState('');
    const [msgColor, setMsgColor] = useState({});

    const handleCheckbox = e => setRememberMe(e.target.checked);

    const setVisibility = () => {
        if(visible){
            setVisible(false);
            setpwdtype('password');
        }
        else {
            setVisible(true);
            setpwdtype('text');
        }
    }
    
    const loginHandler = async e => {
        e.preventDefault();

        try {
            const response = await axios({
                method: "POST",
                url: "/api/login",
                data: {
                    email, password, remember_me: rememberMe
                }
            });

            if(response.status === 200){
                setMsg(response.data.success);
                setMsgColor({color: "#2ed573"});
                setTimeout(()=>{
                    history.push('/');
                    authMemo.login(response.data.user_group);
                }, 500);
            }
            
        } catch (error) {  
            console.log(error);
            let errorMessage = error.response.data.error;
            if(errorMessage)
                setMsg("*"+ errorMessage);
            else 
                setMsg("Something went wrong try again later");
        }
    }

    // functio to remove app download popup from UI
    const removeAppDownloadPopup = () => {
        const _ = document.querySelector('.app_download');
        _ && _.remove();
    }

  return (
    <div className="background">
        <div className="login_wrapper">
            <div className="image_preview">
                <img alt="Login preview" src={login} />
            </div>
            <div className="form_container">
                <div className="login_head">
                    <img src={bus} alt="logo" />
                    <h1>Login</h1>
                </div>
                <form>
                    <label>
                        Email
                        <input 
                            type="email" 
                            placeholder="abc@xyz.com" 
                            value={email}
                            onChange={e=>setEmail(e.target.value)}
                            />
                    </label>
                    <label>
                        Password
                        <input 
                            type={pwdtype} 
                            placeholder="Password" 
                            value={password}
                            onChange={e=>setPassword(e.target.value)}
                            />
                        <div className="pwd_visible">
                            <IconButton onClick={setVisibility}>
                                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}                            
                            </IconButton>
                        </div>
                    </label>
                    <div className="rm_fp">
                        <FormControlLabel
                        value="end"
                        control={
                            <Checkbox 
                                checked={rememberMe} 
                                onChange={handleCheckbox}
                                color="primary" 
                            />
                        }
                        label="Remember me"
                        labelPlacement="end"
                        />
                        <Link to="/forgot_password">Forgot Password?</Link>
                    </div>
                    <div className="error_msg" style={msgColor}>{msg}</div>
                    <button className="btn" onClick={loginHandler}>Login</button>
                </form>
                <div className="no_acc">
                    Don't have an account? 
                    <Link to="/signup">Signup</Link>
                </div>
            </div>
        </div>
        
        <div className="app_download">
            <div className="ad_msg">
                Our Android 
                <img src={android} alt="Download android app" />
                app is live. &nbsp; 
                <a href="https://play.google.com/store/apps/details?id=com.graphicera.gehubusapp" target="_blank">
                    Download now
                </a>
            </div>
            <div className="dismiss_btn">
                <IconButton onClick={removeAppDownloadPopup}>
                    <CloseIcon />
                </IconButton>
            </div>            
        </div>
    </div>
  );
}

export default Login;
