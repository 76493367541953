import axios from 'axios';
import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './styles/editprofile.scss';

const UpdatePassword = () => {

    const history = useHistory();

    const [visible, setVisible] = useState(false);
    const [pwdtype, setpwdtype] = useState('password');
    const setVisibility = () => {
        if(visible){
            setVisible(false);
            setpwdtype('password');
        }
        else {
            setVisible(true);
            setpwdtype('text');
        }
    }

    const [msg, setMsg] = useState('');
    const [data, setData] = useState({
        oldPassword: "", newPassword: "", confirmPassword: ""
    });

    const handleInputs = e => setData({...data, [e.target.name]: e.target.value});

    const UpdatePassword = async e => {

        e.preventDefault();
        setMsg('');

        if(!data.oldPassword || data.oldPassword.length > 30 || data.oldPassword.length < 8){
            setMsg('*Old Password must contain only 8 - 30 charachters');
            return;
        }
        if(!data.newPassword || data.newPassword.length > 30 || data.newPassword.length < 8){
            setMsg('*New Password must contain only 8 - 30 charachters');
            return;
        }else if(data.newPassword !== data.confirmPassword){
            setMsg('Passwords do not match');
            return;
        }

        try {
            const response = await axios({
                method: 'PATCH',
                url: 'api/update_password',
                data: {
                    oldPassword: data.oldPassword, 
                    newPassword: data.newPassword
                }
            });

            if(response.status === 200){
                setMsg('Password changed successfully');
                setTimeout(() => history.push('/pass'), 500);
            }  

        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setMsg(emsg);
            else    
                setMsg('Some error occured. Please try again later');
        }
    }

    return (
        <div className="edit_profile_wrapper">
            <div className="edit_container">
            <div className="edit_header">
                <IconButton onClick={() => history.goBack()}>
                    <ArrowBackIcon />
                </IconButton>
                <h2>Update Password</h2>
            </div>
                <form>
                    <label>
                        Old Password
                        <span className="primary_icon">
                            <IconButton onClick={setVisibility}>
                                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </span>
                        <input 
                        type={pwdtype} 
                        value={data.oldPassword}
                        name="oldPassword"
                        onChange={handleInputs}  />
                    </label>
                    <label>
                        New Password
                        <input 
                        type={pwdtype} 
                        value={data.newPassword}
                        name="newPassword"
                        onChange={handleInputs}  />
                    </label>
                    <label>
                        Confirm New Password
                        <input 
                        type={pwdtype} 
                        value={data.confirmPassword}
                        name="confirmPassword"
                        onChange={handleInputs}  />
                    </label>
                    <div className="error_msg">{msg}</div>
                    <button className="btn" onClick={UpdatePassword}>Change Password</button>
                </form>
                <Link to="/pass">Back to home</Link>
            </div>            
        </div>
    )
}

export default UpdatePassword
