import {React, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from "@material-ui/core";
import axios from "axios";

import bus from '../assets/images/bus.png';
import login from '../assets/images/login.jpg';

import './styles/login.scss';

function Signup() {

    const history = useHistory();

    document.title = "Signup";

    const [visible, setVisible] = useState(false);
    const [pwdtype, setpwdtype] = useState('password');
    const [user, setUser] = useState({
        name: "", email: "", password: "", cpassword: "", mobile_no: ""
    });
    const [msg, setMsg] = useState('');

    const handleInputs = e => setUser({...user, [e.target.name]: e.target.value});

    const handleSubmit = async e => {
        e.preventDefault();
        const { name, email, mobile_no, password, cpassword } = user;

        if(name.length > 30 || name.length < 3){
            setMsg("* Name must contain 3 - 30 charachters");
            return;
        }
        else if(email.length > 100 || email.length < 10){
            setMsg("* Email must contain 10 - 100 charachters");
            return;
        }
        else if(mobile_no < 1000000000 || mobile_no > 9999999999){
            setMsg('Mobile number must be of 10 digits');
            return; 
        }
        else if(password.length > 30 || password.length < 8){
            setMsg("* Password must contain 8 - 30 charachters");
            return;
        }
        else if(password !== cpassword){
            setMsg("Passwords do not match");
            return;
        }

        try {
            const response = await axios({
                method: "POST",
                url: "/api/register",
                data: {
                    name, email, mobile_no, password
                }
            });
            
            if(response.status === 201){
                setMsg('Registeration successfull')
                setTimeout(() => {
                    history.push('/login');
                }, 500);
            }

        } catch (error) {
            if(error.response)
                setMsg(error.response.data.error);
            else
                setMsg('Cannot register. Please try again later');
        }
    }

    const setVisibility = () => {
        if(visible){
            setVisible(false);
            setpwdtype('password');
        }
        else {
            setVisible(true);
            setpwdtype('text');
        }
    }

  return (
    <div className="background">
        <div className="login_wrapper">
            <div className="image_preview">
                    <img alt="Login preview" src={login} />
            </div>
            <div className="form_container">
                <div className="login_head">
                    <img src={bus} alt="logo" />
                    <h1>Signup</h1>
                </div>
                <form method="POST">
                    <label>
                        Full Name
                        <input type="text" name="name" placeholder="eg. John Doe"
                            value={user.fname}
                            onChange={handleInputs}
                         />
                    </label>
                    <label>
                        Email
                        <input type="email" name="email" placeholder="abc@xyz.com"
                            value={user.email}
                            onChange={handleInputs}
                         />
                    </label>
                    <label>
                        Mobile number
                        <input type="number" name="mobile_no" placeholder="Your contact number"
                            value={user.mobile_no}
                            onChange={handleInputs}
                         />
                    </label>
                    <label>
                        Password
                        <input type={pwdtype} name="password" placeholder="Password"
                            value={user.password}
                            onChange={handleInputs}
                         />
                        <div className="pwd_visible">
                            <IconButton onClick={setVisibility}>
                                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}                            
                            </IconButton>
                        </div>
                    </label>
                    <label>
                        Confirm Password
                        <input type={pwdtype} name="cpassword" placeholder="Re-enter Password"
                            value={user.cpassword}
                            onChange={handleInputs}
                         />
                    </label>
                    <div className="error_msg">{msg}</div>
                    <button className="btn" onClick={handleSubmit}>Signup</button>
                </form>
                <div className="no_acc">
                    Already have an account? &nbsp;
                    <Link to="/login">Login</Link>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Signup;
