import {React, useState} from 'react';
import { TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const AddBusModal = (props) => {

    const { open, handleClose,
            setPopupMessage, setPopupSeverity, setPopupState,
            setBusDataChanged
        } = props;

    const [bus, setBus] = useState({});
    const [msg, setMsg] = useState('');
    const handleInputs = e => setBus({...bus, [e.target.name]: e.target.value});

    const addBus = async () => {
        const { bus_no, seating_capacity, route } = bus;
        // setting error message empty
        setMsg('');

        // if variable is empty then it wont check for the condition 
        // and hence error message wont show
        if(!bus_no || bus_no < 1){
            setMsg("Bus number must be greater than 1");
            return;
        }
        else if(!seating_capacity || seating_capacity < 10){
            setMsg("Seating capacity must be greater than 10");
            return;
        }
        else if(!route || (route.length < 10 && route.length > 250)){
            /* 
            type(null or undefinded) checking for 'route' as length is undefined for other variables,
            hence run time error
            */
            setMsg("Route length must be within 10 - 250 characters");
            return;
        }

        try {
            const response = await axios({
                method: "POST",
                url: "/api/bus",
                data: {
                    bus_no, seating_capacity, route
                }
            });

            if(response.status === 201){
                setPopupMessage(response.data.success);
                setPopupSeverity('success');
                setPopupState(true);
                handleClose();

                // Changing busdatachange so that bus data loads again
                setBusDataChanged(preVal => !preVal);
            }
        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setMsg(emsg)
            else 
                setMsg('An unknown error occured. Please try again later');
        }
    }
    
    return(
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add new Bus</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Please enter the details
                <form>
                    <TextField 
                        label="Bus number"
                        name="bus_no"
                        value={bus.bus_no}
                        type="number"
                        onChange={handleInputs}
                        fullWidth />
                    <TextField 
                        label="Seating capacity"
                        name="seating_capacity"
                        value={bus.seating_capacity}
                        onChange={handleInputs}
                        fullWidth />
                    <TextField 
                        label="Route"
                        name="route"
                        value={bus.route}
                        onChange={handleInputs}
                        multiline
                        fullWidth />
                </form>
                <div className="error_msg">{msg}</div>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={addBus} color="primary">
                Add
            </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddBusModal;
