import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import axios from 'axios'; 

const DeleteDriverModal = (props) => {

    const {dmopen, dmhandleClose, driver, setPopupState, setPopupMessage, setPopupSeverity, setDriverDataChanged } = props;

    const deleteDriver = async () => {
        if(!driver.email) return;

        try {
            
            const response = await axios ({
                method: 'DELETE',
                url: '/api/driver/' + driver._id
            })

            if(response.status===200){
                if(response.data.success){
                    setPopupMessage(response.data.success)
                    setPopupSeverity('success');

                    // Changing driverdatachange so that driver data loads again
                    setDriverDataChanged(preVal => !preVal);
                } else if(response.data.error) {
                    setPopupMessage(response.data.error);
                    setPopupSeverity('error');                    
                }
            }
            
        } catch (error) {
            let emsg = error.response.data.error;
            if(emsg)
                setPopupMessage(emsg);
            else 
                setPopupMessage('Some error occured. Please try again later');

            setPopupSeverity('error');
        } finally{
            setPopupState(true);
            dmhandleClose();
        }
    }

  return (
    <div>
      <Dialog
        open={dmopen}
        onClose={dmhandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove driver</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user permanently
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteDriver} color="primary">
            Yes
          </Button>
          <Button onClick={dmhandleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

export default DeleteDriverModal;