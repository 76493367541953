import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import axios from 'axios';

const UpdateTermPopup = (props) => {

    const {open, handleClose, setPopupState, setPopupMessage, setPopupSeverity, termEndDate} = props;

    const updateTerm = async () => {
        try {
            const response = await axios.patch('/api/update_term', {termEndDate});
            if(response.data.success){
                setPopupMessage('Term updated successfully');
                setPopupSeverity('success');
            }
        } catch (error) {
            let errorMsg = "Something went wrong. Please try again later";
            if(error.response) errorMsg = error.response.data.error;
            setPopupMessage(errorMsg);
            setPopupSeverity('error');
        }finally{
            setPopupState(true);
            handleClose();
        }
    }

    return(
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update current term</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to update the term?
            <p style={{color: 'red', fontWeight: 700}}>
              WARNING: 
              This action cannot be undone
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateTerm} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      );
}

export default UpdateTermPopup;