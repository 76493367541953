import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';

import Error404 from '../Error404';

import { AuthContext } from '../auth/AuthProvider';

const AdminRoute = ({path, component}) => {

    const { authState } = useContext(AuthContext);

    return (
        <Route 
          exact path={path}
          render={() =>
            authState.isLoggedIn ? (
              authState.userGroup === 'Admin' ?
                component
              :
                <Error404 />
            ) 
            :
              <Redirect to='/login' />
          }
        />
    )
}

export default AdminRoute;
