import {React} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const DeleteBusModal = (props) => {

    const {
      dmopen, dmhandleClose, bus, setPopupState, setPopupMessage, setPopupSeverity,
      setBusDataChanged
    } = props 

    const {bus_no} = bus;

    const deleteBus = async () => {
        if(!bus_no) return;

        try {

            const response = await axios({
                method: 'DELETE',
                url: '/api/bus/'+bus_no
            });
            
            if(response.status === 200){
              if(response.data.error){
                setPopupMessage(response.data.error);
                setPopupSeverity('error');

                // Changing busdatachange so that bus data loads again
                setBusDataChanged(preVal => !preVal);
              }else if(response.data.success){
                setPopupMessage(response.data.success);
                setPopupSeverity('success');
              }
            }
            dmhandleClose();
            
        } catch (error) {
            setPopupMessage('Something went wrong! Please try again later');            
        } finally{
          setPopupState(true);
        }
    }

    return (
      <div>
        <Dialog
          open={dmopen}
          onClose={dmhandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove bus</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete bus number {bus_no} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteBus} color="primary">
              Yes
            </Button>
            <Button onClick={dmhandleClose} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default DeleteBusModal;
