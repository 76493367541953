import { React, useEffect, useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';

import Navbar from '../components/Navbar';
import BusTable from '../components/tables/BusTable';
import DeleteBusModal from '../components/dialogs/DeleteBusModal';
import AddBusModal from '../components/dialogs/AddBusModal';
import EditBusModal from '../components/dialogs/EditBusModal';
import Popup from '../components/dialogs/Popup';

import './styles/container.scss';

import no_data from '../assets/images/no_data.jpg';

function Buses() {
    
    document.title = 'Buses';

    const [busData, setBusData] = useState([]);
    const [driversAvailable, setdriversAvailable] = useState([]);

    // Whenever this data changes loadBuses is called again as it is a dependency in useffect
    // changed in add / delete / edit bus
    const [busDataChanged, setBusDataChanged] = useState(false);

    const loadBuses = async () => {
        try {
            
            const response = await axios.get('/api/bus');
            if(response.status === 200){
                const data = response.data;
                
                // sorting the data according to bus number
                data.sort((a,b) => a.bus_no >= b.bus_no ? 1 : -1)
                setBusData(data);
            }
            
        } catch (error) {
            console.log(error);
        }
    }
    
    const loadDrivers = async () => {
        try {            
            const response = await axios.get('/api/drivers');
            if(response.status === 200){
                setdriversAvailable(response.data)
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadBuses();
    }, [busDataChanged]);
    
    // using different hook as driverdata isnt dependent on bus data / bus data change
    useEffect(() => {
        loadDrivers();
    }, []);


    const [open, setOpen] = useState(false);
    const [dmopen, setdmOpen] = useState(false);
    const [editModalOpen, seteditModalOpen] = useState(false);
    const [tempBus, setTempBus] = useState({}); //this will store bus data for editing

    // For add bus modal
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // For edit Modal
    const handleEditModalOpen = (bus) => {
        setTempBus(bus);
        seteditModalOpen(true);
    }
    const handleEditModalClose = () => seteditModalOpen(false);

    //For delete modal
    const dmhandleClickOpen = (bus) => {
        setTempBus(bus);
        setdmOpen(true);
    }
    const dmhandleClose = () => setdmOpen(false);

    const [popupState, setPopupState] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupSeverity, setPopupSeverity] = useState('');

    return (
        <>
        <div className="container">
        <Navbar />
            <div className="data_wrapper">
                <h1>Buses</h1>
                <div className="bus_data">
                {
                    busData.error ? 
                    (
                        <div className='no_data_div'>
                            <h3>OOPs!! no data found</h3>
                            <img 
                                src={no_data} 
                                alt='No data found' 
                                class="no_data_image"
                            />
                        </div>
                    )
                    :
                    <BusTable 
                        buses={busData} 
                        dmhandleClickOpen={dmhandleClickOpen} 
                        handleEditModalOpen={handleEditModalOpen}
                    />
                }
                </div>
            </div>
            <Tooltip title="Add a bus" arrow>
                <Button 
                    startIcon={<AddBoxIcon />}
                    variant="contained"
                    className="add_btn"
                    color="primary"
                    onClick={handleClickOpen}
                >
                    Add a Bus
                </Button>
            </Tooltip>
        </div>
        <AddBusModal 
            open={open}
            handleClose={handleClose}
            driversAvailable={driversAvailable}
            tempBus={tempBus}
            setPopupMessage={setPopupMessage}
            setPopupSeverity={setPopupSeverity}
            setPopupState={setPopupState}
            setBusDataChanged={setBusDataChanged}
        />
        <EditBusModal 
            editModalOpen={editModalOpen}
            handleEditModalClose={handleEditModalClose}
            driversAvailable={driversAvailable}
            tempBus={tempBus}
            setPopupMessage={setPopupMessage}
            setPopupSeverity={setPopupSeverity}
            setPopupState={setPopupState}
            setBusDataChanged={setBusDataChanged}
        />
        <DeleteBusModal
            dmopen={dmopen}
            dmhandleClose={dmhandleClose}
            bus={tempBus}
            setPopupMessage={setPopupMessage}
            setPopupSeverity={setPopupSeverity}
            setPopupState={setPopupState}
            setBusDataChanged={setBusDataChanged}
        />
        <Popup
            open={popupState}
            severity={popupSeverity}
            message={popupMessage}
            setPopupState={setPopupState}
        />       
        </>
    )
}

export default Buses;
