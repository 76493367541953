import React from 'react';
import { Link } from 'react-router-dom';
import Button from './../components/buttons/Button';

const Privacy = () => {

    document.title = "Privacy Policy - GEHU Bus App";

  return (
    <div className='container'>
        <div className="privacy_policy">
            <h1>Privacy Policy</h1>
            <h3>What Information Do We Collect?</h3>
            <p>
                The personal information that we collect collect may include the following:
                <ul>
                    <li>Name</li>
                    <li>email</li>
                    <li>Phone number</li>
                </ul>
                <strong>Sensitive Information:</strong> We do not process sensitive information.
            </p>
            <h3>Is Mine Information shared?</h3>
            <p>
                No. The data shared with us is never shared with anyone.
            </p>

            <div className="btn_div">
                <Link to={'/'}>
                    <Button 
                        text="Back to home"
                    />
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Privacy