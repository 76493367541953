import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Passpagefooter from '../components/Passpagefooter';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';

import { AuthContext } from '../auth/AuthProvider';

import './styles/pass.scss'

function Settings() {

    // using history hook for pushing login route after logout and not render the error component
    const history = useHistory();

    const {authMemo} = useContext(AuthContext);

    return (
        <div className="userdata_container">
            <div className="card">
                <ul className="settings_option">
                    <Link to="/editprofile">
                        <li>
                            Edit Profile
                            <EditIcon />
                        </li>
                    </Link>
                    <Link to="/update_password">
                        <li>
                            Update Password
                            <LockIcon />
                        </li>
                    </Link>
                    <p onClick={() => {
                            authMemo.logout()
                            history.push('/login');
                        }} className='logout'>
                        <li>
                            Logout
                            <ExitToAppIcon />
                        </li>
                    </p>
                </ul>
            </div>
            <Passpagefooter />
        </div>
    )
}

export default Settings
