import { useContext } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import axios from 'axios';

// Pages
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Pass from './pages/Pass';
import Settings from './pages/Settings';
import Editprofile from './pages/Editprofile';
import Buses from './pages/Buses';
import Drivers from './pages/Drivers';
import Error404 from './Error404';
import Signup from './pages/Signup';
import Updatestudent from './pages/Updatestudent';
import UpdatePassword from './pages/UpdatePassword';
import ViewAttendance from './pages/ViewAttendance';

import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

import Privacy from './pages/Privacy';

import { AuthContext } from './auth/AuthProvider';

import AdminRoute from './ProtectedRoutes/AdminRoute';

import './App.css';
import './globals/colors.css';
import UserRoute from './ProtectedRoutes/User';

function App() {

  const {authState, authMemo} =  useContext(AuthContext);

  /*
    *if API returns status 401 unauthorised, log out user from the application
    * -- on logout automatically show login screen --
    *Checking on app component because this component checks authentication first
  */ 
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if(error.response){
      if(error.response.status === 401){
        authMemo.logout();
      }
    }
    return Promise.reject(error);
  })

  /*
  This component checks for authState and user type
    routes are visible according to user group.
    Authentication is checked on initial load in AuthProvider using 
    useEffect hook
    */
   return (
     <div className="App">
      <Router>
        <Switch>

          {/*This route is to handle the default "/" case */}
          <Route 
            exact path="/"
            render={() =>
              authState.isLoggedIn ? (
                authState.userGroup === 'Admin' ?
                <Redirect to='/dashboard' />
                :
                <Redirect to='/pass' />
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          {/* Auth Routes */}
          <Route 
            exact path="/login"
            render={() =>
              authState.isLoggedIn ?
                <Redirect to='/' />
              :
                <Login />
            }
          />
          <Route 
            exact path="/signup"
            render={() =>
              authState.isLoggedIn ?
                <Redirect to='/' />
              :
                <Signup />
            }
          />

          <Route exact path="/privacy-policy" component={Privacy} />

          <Route exact path="/forgot_password" component={ForgotPassword} />
          {/* matching all as token might contain '/' which would treat it as another parameter */}
          <Route exact path="/reset_password/:selector/*" component={ResetPassword} />
          

          {/* Common Routes */}
          <UserRoute path="/settings" component={<Settings />} />
          <UserRoute path="/editprofile" component={<Editprofile />} />
          <UserRoute path="/update_password" component={<UpdatePassword />} />

          {/* Student Routes */}
          <UserRoute path="/pass" component={<Pass />} />

          {/* Admin Routes */}
          <AdminRoute path="/dashboard" component={<Dashboard />} />
          <AdminRoute path="/buses" component={<Buses />} />
          <AdminRoute path="/drivers" component={<Drivers />} />
          <AdminRoute path="/update_student" component={<Updatestudent />} />
          <AdminRoute path="/view_attendance" component={<ViewAttendance />} />

          {/* else render error component */}
          <Route path="/*" component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
