import React, { useContext } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import GroupIcon from '@material-ui/icons/Group';
import BookIcon from '@material-ui/icons/Book';
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Tooltip } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

import { AuthContext } from '../auth/AuthProvider';

import avatar from '../assets/images/avatar.png';
import bus from '../assets/images/bus.png'
import './navbar.scss';

function Navbar() {

    // using history hook for pushing login route after logout and not render the error component
    const history = useHistory();

    const {authState, authMemo} = useContext(AuthContext);

    return (
        <div className="navbar">
            <div className="navbar_body">
                <div className="logo">
                    <img src={bus} alt="Logo"/>
                    <p>GEHU Bus</p>
                </div>
                <ul className="links">
                        <NavLink to="/dashboard" activeClassName="active">                                
                            <li>
                                <DashboardIcon />
                                Dashboard
                            </li>
                        </NavLink>
                        <NavLink to="/buses" activeClassName="active">                                
                            <li>
                                <DirectionsBusIcon />
                                Buses
                            </li>
                        </NavLink>
                        {/* <NavLink to="/students" activeClassName="active">                                
                            <li>
                                <AssignmentIndIcon />
                                Students
                            </li>
                        </NavLink> */}
                        <NavLink to="/drivers" activeClassName="active">                                
                            <li>
                                <GroupIcon />
                                Drivers
                            </li>
                        </NavLink>
                        <NavLink to="/update_student" activeClassName="active">
                            <li>
                                <UpdateIcon />
                                Update Student
                            </li>
                        </NavLink>
                        <NavLink to="/view_attendance" activeClassName="active">
                            <li>
                                <BookIcon />
                                View Attendance
                            </li>
                        </NavLink>
                </ul>   
            </div>    
            <div className="navbar_footer">
                <div className="footer_wrapper">
                    <img src={avatar} alt="avatar" />
                    <div className="user_details">
                        <p>Mayank Singh</p>
                        <p>{authState.userGroup}</p>
                    </div>
                    <div className="logout_icon">
                        <Tooltip title="logout" arrow>
                            <ExitToAppIcon onClick={() => {
                                        authMemo.logout()
                                        history.push('/login')
                                    }
                                }/>
                        </Tooltip>
                    </div>
                </div>
            </div>     
        </div>
    )
}

export default Navbar
