import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

// Date picker
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// custom components
import Navbar from '../components/Navbar';
import AttendanceTable from '../components/tables/AttendanceTable';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const ViewAttendance = () => {

    document.title = "View Attendance";

    const [busNumber, setBusNumber] = useState(null);

    const [attendanceList, setAttendanceList] = useState(null);

    // state to show error message in case attendance is not found
    const [error, setError] = useState(false);

    // state to show error if bus number is not selected
    const [busNumberError, setBusNumberError] = useState(false);

    // store buses data (bus number)
    const [buses, setBuses] = useState(null);

    // styles used by Material UI
    const classes = useStyles();

    const handleBusNumberChange = event => setBusNumber(event.target.value);

    // Date state
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleDateChange = date => setSelectedDate(date);

    const fetchAttendance = async () => {

        if(!busNumber){
            setBusNumberError(true);
            return;
        };

        setBusNumberError(false);
        setError(false);
        setAttendanceList(null); //setting it null in case response not found for new request, so both list and error message will show at the same time

        const currentDate = selectedDate.getDate();
        const month = selectedDate.getMonth() + 1; // month indexing is 0 based
        const year = selectedDate.getFullYear();
        try {
            const response = await axios({
                method: 'GET',
                url: `/api/attendance/${busNumber}/${currentDate}/${month}/${year}`,
            })
            if(response.status === 200 && response.data.success)
                setError(true); //if empty list is returned by API show error message
            else
                setAttendanceList(response.data); //else update the list and show attendance
        } catch (error) {
            console.log(error);
        }
    }
    
    const fetchBuses = async () => {
        try {
            const response = await axios.get('/api/bus_numbers');
            if(response.status === 200){
                setBuses(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchBuses();
    }, [])

    return (
        <div className='container'>
            <Navbar />
            <div className="data_wrapper view_attendance">
                <h2>View Attendance</h2>
                <div className="input_div">
                    <FormControl className={classes.formControl} error={busNumberError}>
                        <InputLabel id="demo-simple-select-label">Bus number</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={busNumber}
                        onChange={handleBusNumberChange}
                        >
                        {
                            buses && !buses.error &&
                            buses.map(bus => {
                                return <MenuItem value={bus.bus_no}>{bus.bus_no}</MenuItem>
                            })
                        }
                        </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Select Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Button variant="contained" color="primary" onClick={fetchAttendance}>View</Button>
                </div>
                {
                    attendanceList &&
                    <div className="attendance_table_div">
                        <h2 className='table_heading'>Attendance: </h2>
                        <AttendanceTable 
                            attendanceList={attendanceList}
                        />
                    </div>
                }
                {
                    error &&
                    <Alert severity="error">
                        <AlertTitle><strong>No record!!</strong></AlertTitle>
                        No attendace record found for selected bus number on selected date.
                    </Alert>
                }
            </div>
        </div>
    )
}

export default ViewAttendance
