import { React, useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import axios from "axios";

import Pass from '../components/Pass'
import NoPass from '../components/dialogs/NoPass';
import Passpagefooter from '../components/Passpagefooter';
import Profile from '../components/skeletons/Profile';

import './styles/pass.scss';

function PassPage() {

    document.title = "Pass";

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isStudent, setIsStudent] = useState(false);

    const [userData, setUserData] = useState({});

    const userDataLoader = async () => {

        try {
            const response = await axios.get('/api/profile');

            if(response.status === 200){
                setUserData(response.data); 
                
                if(response.data.is_student !== undefined)
                    setIsStudent(true);

            }
        } catch (error) {
            history.push('/login')
        }finally{
            setIsLoading(false);
        }
    }

    useEffect(() => {            
        userDataLoader();

        // Disabling eslint empty dependency warning
        // eslint-disable-next-line
    }, []);

    // Pass skeleton view
    if(isLoading) return <Profile />

    return (
        <>
            <div className="userdata_container">
                {isStudent ? <Pass userData={userData} /> : <NoPass />}
                <Passpagefooter />
            </div>
        </>
    );
}

export default PassPage
